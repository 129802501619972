export const TrustFilters = () => {
  return [{
    label: 'Cloud Fortified',
    id: 'cloudFortified',
    value: 'approved'
  }, {
    label: 'Cloud Security Participant',
    id: 'bugBountyParticipant',
    value: 'bugBountyApproved'
  }];
};