import { ANALYTICS_SCREEN_MANAGE_APP_ACTIVE_INSTALLS, ANALYTICS_SCREEN_MANAGE_APP_ADMIN, ANALYTICS_SCREEN_MANAGE_APP_DETAILS, ANALYTICS_SCREEN_MANAGE_APP_DOWNLOADS, ANALYTICS_SCREEN_MANAGE_APP_MARKETING, ANALYTICS_SCREEN_MANAGE_APP_PRICNG, ANALYTICS_SCREEN_MANAGE_APP_PRIVACY_AND_SECURITY, ANALYTICS_SCREEN_MANAGE_APP_PRIVATE_LISTINGS, ANALYTICS_SCREEN_MANAGE_APP_VERSIONS } from '../analytics/Sources';
export const manageAppsBaseRoute = '/manage/apps/:appId(\\d+)';
export const appVersionsRoute = {
  name: ANALYTICS_SCREEN_MANAGE_APP_VERSIONS,
  path: "".concat(manageAppsBaseRoute, "/versions"),
  isNestedRoute: true
};
export const appEditionsRoute = {
  name: ANALYTICS_SCREEN_MANAGE_APP_VERSIONS,
  path: "".concat(manageAppsBaseRoute, "/app-editions"),
  isNestedRoute: true
};
export const appDetailsRoute = {
  name: ANALYTICS_SCREEN_MANAGE_APP_DETAILS,
  path: "".concat(manageAppsBaseRoute, "/details"),
  isNestedRoute: true
};
export const appPricingRoute = {
  name: ANALYTICS_SCREEN_MANAGE_APP_PRICNG,
  path: "".concat(manageAppsBaseRoute, "/pricing"),
  isNestedRoute: true
};
export const appPrivacyAndSecurityRoute = {
  name: ANALYTICS_SCREEN_MANAGE_APP_PRIVACY_AND_SECURITY,
  path: "".concat(manageAppsBaseRoute, "/privacy-and-security"),
  isNestedRoute: true
};
export const appActiveInstallsRoute = {
  name: ANALYTICS_SCREEN_MANAGE_APP_ACTIVE_INSTALLS,
  path: "".concat(manageAppsBaseRoute, "/analytics"),
  isNestedRoute: true
};
export const appPrivateListingsRoute = {
  name: ANALYTICS_SCREEN_MANAGE_APP_PRIVATE_LISTINGS,
  path: "".concat(manageAppsBaseRoute, "/private-listings"),
  isNestedRoute: true
};
export const appDownloadsRoute = {
  name: ANALYTICS_SCREEN_MANAGE_APP_DOWNLOADS,
  path: "".concat(manageAppsBaseRoute, "/downloads"),
  isNestedRoute: true
};
export const appMarketingRoute = {
  name: ANALYTICS_SCREEN_MANAGE_APP_MARKETING,
  path: "".concat(manageAppsBaseRoute, "/marketing"),
  isNestedRoute: true
};
export const appAdminRoute = {
  name: ANALYTICS_SCREEN_MANAGE_APP_ADMIN,
  path: "".concat(manageAppsBaseRoute, "/admin"),
  isNestedRoute: true
};
export const allManageAppsRoutes = [appVersionsRoute, appDetailsRoute, appPricingRoute, appPrivacyAndSecurityRoute, appActiveInstallsRoute, appPrivateListingsRoute, appDownloadsRoute, appMarketingRoute, appAdminRoute];