import { InMemoryCache } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import { GraphQLGatewayPossibleTypes } from '@atlassian/mpac-types/graphql-gateway-types';
import { categoryMergePolicy } from '../dla-handlers/Category/CategoryMergePolicy';
import { collectionMergePolicy } from '../dla-handlers/Collection/collectionMergePolicy';
import { partnerProfileMergePolicy } from '../dla-handlers/PartnerProfile/PartnerProfileMergePolicy';
import { appSearchMergePolicy } from '../dla-handlers/SearchResult/AppSearchMergePolicy';
import { getHostingFilterValue, getVersionHistoryCacheKey } from '../dla-handlers/VersionHistory/VersionHistoryTransposeUtils';
export const createApolloCache = initialState => {
  const cache = new InMemoryCache({
    possibleTypes: GraphQLGatewayPossibleTypes,
    typePolicies: {
      // Workaround for this apollo bug: https://github.com/apollographql/apollo-client/issues/7636
      // by disabling caching for this field (https://bit.ly/371l02H)
      CompatibleAtlassianProduct: {
        keyFields: false
      },
      MarketplaceStoreQueryApi: {
        keyFields: () => 'marketplaceStore'
      },
      Query: {
        fields: {
          marketplaceStorePartner: {
            keyArgs: ['vendorId'],
            merge: partnerProfileMergePolicy
          },
          marketplaceSearch: {
            keyArgs: ['query', 'filters', 'sort'],
            merge: appSearchMergePolicy
          },
          marketplaceCategory: {
            keyArgs: ['slug', 'filters', 'sort'],
            merge: categoryMergePolicy
          },
          marketplaceCollection: {
            keyArgs: ['slug', 'sort'],
            merge: collectionMergePolicy
          },
          marketplaceAppVersionHistory: {
            /**
             * marketplaceAppVersionHistory query only accept "appId" as args, by default
             * the caching would be based on "appId" only, & we need to consider
             * filters within caching as well, which is part of the query variables
             */
            keyArgs: (args, _ref) => {
              let {
                variables
              } = _ref;
              const {
                filters
              } = variables;
              const hostingFilter = getHostingFilterValue(filters);
              if (!args) {
                return false;
              }
              return getVersionHistoryCacheKey(args.appId, hostingFilter);
            }
          },
          marketplaceHome: {
            keyArgs: ['productId', 'first', 'after']
          }
        }
      },
      MarketplaceVersionHistory: {
        fields: {
          // Note: for relayStylePagination to work appropriately the args like 'after' etc.
          // should be a part of this 'MarketplaceVersionHistory' type
          // & NOT the top level parent 'marketplaceAppVersionHistory' schema type
          // https://www.apollographql.com/docs/react/pagination/cursor-based/#relay-style-cursor-pagination
          versions: relayStylePagination()
        }
      }
    }
  });
  if (typeof initialState !== 'undefined') {
    cache.restore(initialState);
  }
  return cache;
};