import { fetchOk } from '@atlassian/mpac-fetch-wrapper';
export const archiveApp = (mpacBaseUrl, appKey) => fetchOk("".concat(mpacBaseUrl, "/rest/1.0/plugins/").concat(appKey, "/archive"), {
  method: 'POST',
  headers: {
    'Content-type': 'application/json'
  },
  body: JSON.stringify({
    hostings: ['cloud', 'server', 'datacenter']
  })
});
export const deleteApp = (mpacBaseUrl, appKey) => fetchOk("".concat(mpacBaseUrl, "/rest/2/addons/").concat(appKey), {
  method: 'DELETE'
});
export const getApp = async appKey => {
  return fetchOk("/rest/2/addons/".concat(appKey)).then(response => response.json());
};
export const saveAdminData = async (mpacBaseUrl, appKey, payload) => {
  return fetch("".concat(mpacBaseUrl, "/rest/2/addons/").concat(appKey), {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json-patch+json'
    },
    body: JSON.stringify(payload)
  }).then(async response => {
    if (!response.ok) {
      try {
        const result = await response.json();
        return result;
      } catch (error) {
        throw new Error(error, {
          cause: response.statusText
        });
      }
    }
  }).catch(err => {
    throw new Error(err, {
      cause: err.message
    });
  });
};