import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { saveAdminData } from '@atlassian/mpac-api-client';
import { BaseHandler } from '../../../graphql-orchestrator-link';
export class AppAdminSubmitFormHandler extends BaseHandler {
  constructor(mpacBaseUrl) {
    super();
    _defineProperty(this, "mpacBaseUrl", void 0);
    this.mpacBaseUrl = mpacBaseUrl;
  }
  async fetchCustom(operation, _transformedOperation, _sequenceConfigValue, _primaryResponse) {
    const {
      appKey,
      payload
    } = operation.variables;
    const response = await saveAdminData(this.mpacBaseUrl, appKey, payload);
    return response;
  }
  responseTransformer(_operation, _transformedOperation, _sequenceConfigValue, _primaryResponse, _customResponse) {
    if (_customResponse && _customResponse !== null && _customResponse !== void 0 && _customResponse.errors) {
      throw new Error(JSON.stringify(_customResponse === null || _customResponse === void 0 ? void 0 : _customResponse.errors));
    }
    return {
      marketplaceConsoleAdminSaveForm: {
        success: true
      }
    };
  }
}