import { getFeatureFlagsClient, MarketplaceType } from '@atlassian/mpac-feature-flags';
import { LocalStorageUtils, tryParseJSON } from '@atlassian/mpac-utils';
import { keysToCamelCase } from '@atlassian/mpac-utils/object';
import merge from 'lodash/fp/merge';

import ldAnonymousId from './ldAnonymousId';

import type { ApplicationConfig } from '../application-config/types/BootstrapConfig';
import type { FrontendBootstrap } from '@atlassian/mpac-api-client';
import type { StatsigClientType } from '@atlassian/mpac-feature-flags';
import type {
  MarketplaceFeatureFlags,
  StatsigExperiments,
  StatsigFeatureFlags,
} from '@atlassian/mpac-types';
import type AnalyticsWebClient from '@atlassiansox/analytics-web-client';

type GetFeatureFlagsArgs = {
  frontendBootstrap: FrontendBootstrap;
  analyticsClient: AnalyticsWebClient;
  applicationConfig: ApplicationConfig;
  sourceQueryParam: string;
  useFrontendBranch?: string;
  variant: string;
  statsigClient: StatsigClientType;
};

export const getFeatureFlags = async ({
  frontendBootstrap,
  analyticsClient,
  applicationConfig,
  sourceQueryParam,
  useFrontendBranch,
  variant,
  statsigClient,
}: GetFeatureFlagsArgs): Promise<MarketplaceFeatureFlags> => {
  const { launchDarklyUserKey: userId } = frontendBootstrap;
  const {
    featureFlags: { apiKey, environment },
    statsig,
  } = applicationConfig;

  const commonCustomAttributes = {
    marketplaceType: MarketplaceType.MPAC,
    source: sourceQueryParam,
    ...(!!useFrontendBranch && {
      useFrontendBranch,
    }),
    variant,
    userAgent: navigator.userAgent,
  };

  const statsigCustomAttributes = {
    ...commonCustomAttributes,
    userId,
  };

  const customAttributes = {
    ...commonCustomAttributes,
    ldAnonymousId: ldAnonymousId.get(),
  };

  // init LD
  const featureFlagClient = await getFeatureFlagsClient({
    apiKey,
    environment,
    userId,
    analyticsClient,
    productKey: 'marketplace',
    customAttributes,
  });

  // init statsig client (it should init for both client and ssr)
  await statsigClient.initializeStatsigClient(
    {
      clientKey: statsig.clientKey,
      environment: statsig.environment,
      targetApp: statsig.targetApp,
      marketplaceAnonymousId: userId,
    },
    statsigCustomAttributes
  );

  const allFlags: MarketplaceFeatureFlags = keysToCamelCase(featureFlagClient.getAllFlags().toJS());

  if (process.env.ALLOW_LOCAL_STORAGE_FEATURE_FLAG_OVERRIDES) {
    /*
     * The `process.env.ALLOW_LOCAL_STORAGE_FEATURE_FLAG_OVERRIDES` check here ensures this code never exists in production bundles.
     * It only exists in the bundle created for running E2E tests, since `process.env.ALLOW_LOCAL_STORAGE_FEATURE_FLAG_OVERRIDES` is
     * set to `true` only in E2E tests. This depends on webpack optimization removing the code block
     *  when the condition is statically `false`.
     */
    const overridesRaw = LocalStorageUtils.getFromStorage('marketplace.feature-flags-overrides');

    const { success: hasOverrides, result: overrides } =
      tryParseJSON<Partial<MarketplaceFeatureFlags & StatsigExperiments & StatsigFeatureFlags>>(
        overridesRaw
      );

    if (hasOverrides) {
      statsigClient.setOverride(overrides);

      return merge(allFlags, overrides);
    }
  }

  return allFlags;
};
