import { createAutocomplete } from '@algolia/autocomplete-core';
import AlgoliaSearch from 'algoliasearch/lite';
import { useCallback, useMemo, useState } from 'react';
import { HIGHLIGHT_POST_TAG, HIGHLIGHT_PRE_TAG, SEARCH_INDEX_NAME } from '../constants';
import { debounceAutoComplete } from './DebouceAutocomplete';
// TODO: Replace with value from bootstrap config
const searchClient = AlgoliaSearch('7LVR1JKTDF', '3ef48f0fc67ee7e9c5a9dbbf6b45908c');

// eslint-disable-next-line max-lines-per-function
const useAlgoliaAutocomplete = (inputRef, placeholder, initQuery, handleNewSearch, recentSearch) => {
  const [autocompleteState, setAutocompleteState] = useState({
    collections: [],
    completion: null,
    context: {},
    isOpen: false,
    query: '',
    activeItemId: null,
    status: 'idle'
  });
  const debouncedGetSources = useCallback(_ref => {
    let {
      query
    } = _ref;
    if (!query) {
      return [{
        sourceId: 'recentSearches',
        onSelect(_ref2) {
          let {
            item
          } = _ref2;
          handleNewSearch(item);
        },
        getItemUrl(_ref3) {
          let {
            item
          } = _ref3;
          return item;
        },
        getItems() {
          return recentSearch.getRecentSearches();
        },
        // This sets the value in input field when user selects an item in dropdown
        getItemInputValue(_ref4) {
          let {
            item
          } = _ref4;
          return item.query;
        }
      }];
    }
    return debounceAutoComplete([{
      sourceId: 'querySuggestions',
      onSelect(_ref5) {
        let {
          item
        } = _ref5;
        handleNewSearch(item);
      },
      getItems() {
        return searchClient.search([{
          indexName: SEARCH_INDEX_NAME,
          query,
          params: {
            hitsPerPage: 6,
            snippetEllipsisText: '…',
            highlightPreTag: HIGHLIGHT_PRE_TAG,
            highlightPostTag: HIGHLIGHT_POST_TAG
          }
        }]).then(_ref6 => {
          let {
            results
          } = _ref6;
          const firstResult = results[0];
          const {
            hits
          } = firstResult;
          return hits.filter(hit => hit.query !== query); // Remove the query from the suggestions.
        }).catch(error => {
          console.error('Error fetching query suggestions', error);
          return [];
        });
      },
      getItemUrl(_ref7) {
        let {
          item
        } = _ref7;
        return item;
      },
      // This sets the value in input field when user selects an item in dropdown
      getItemInputValue(_ref8) {
        let {
          item
        } = _ref8;
        return item.query;
      }
    }]);
  }, [handleNewSearch, recentSearch]);
  const autocomplete = useMemo(() => createAutocomplete({
    id: 'search',
    placeholder,
    openOnFocus: true,
    onStateChange(props) {
      setAutocompleteState(props.state);
    },
    getSources: debouncedGetSources,
    initialState: {
      query: initQuery
    },
    navigator: {
      // only called when user presses enter
      // not called when user clicked on a suggestion
      navigate(_ref9) {
        let {
          item
        } = _ref9;
        // slack discussion: https://atlassian.slack.com/archives/C05U00RCWAE/p1711440109816469
        // handle the case when user has hover over a suggestion and pressed enter
        // in this case, we should navigate to the input value and not the suggestion value
        const queryFromInput = inputRef.current.value;

        // if input value is same as hovered suggestion, then navigate to the suggestion because the item object has popularity and other details
        if (item.query === queryFromInput) {
          handleNewSearch(item);
        } else {
          handleNewSearch({
            query: queryFromInput,
            objectID: queryFromInput
          });
        }
      }
    }
  }), [inputRef, initQuery, debouncedGetSources, handleNewSearch, placeholder]);
  return {
    autocomplete,
    autocompleteState
  };
};
export { useAlgoliaAutocomplete };