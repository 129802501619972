import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { getAlgoliaConfig } from '../algolia/utils';
import { ActiveInstallsHandler, AppAdminHandler, AppAdminSubmitFormHandler, AppAdminUploadLogoHandler, CategoryHandler, CollectionHandler, DownloadsHandler, HomeHandler, PartnerProfileHandler, SearchFiltersHandler, SearchResultHandler, VersionHistoryHandler } from '../dla-handlers';
import { SequenceTypes } from '../graphql-orchestrator-link';
const getConfig = config => config;

/**
 * Orchestration configuration for DLA
 *
 * `config` accepts both a config and a function returning config. This can be used for returning
 *  configuration based on some feature flag or some custom logic.
 */

const consoleManageAppsConfig = _config => {
  return {
    GetMarketplaceAppActiveInstalls: {
      config: () => ({
        sequence: SequenceTypes.CUSTOM_ONLY,
        handler: new ActiveInstallsHandler(_config.mpacBaseUrl)
      })
    },
    GetMarketplaceAppDownloads: {
      config: () => ({
        sequence: SequenceTypes.CUSTOM_ONLY,
        handler: new DownloadsHandler(_config.mpacBaseUrl)
      })
    },
    GetMarketplaceManageAppAdmin: {
      config: {
        sequence: SequenceTypes.CUSTOM_ONLY,
        handler: new AppAdminHandler()
      }
    },
    UploadMarketplaceManageAppAdminTitleLogo: {
      config: {
        sequence: SequenceTypes.CUSTOM_ONLY,
        handler: new AppAdminUploadLogoHandler()
      }
    },
    SubmitMarketplaceManageAppAdminData: {
      config: {
        sequence: SequenceTypes.CUSTOM_ONLY,
        handler: new AppAdminSubmitFormHandler(_config.mpacBaseUrl)
      }
    }
  };
};

// eslint-disable-next-line max-lines-per-function
const orchestrationMpacConfig = configProps => {
  const algoliaConfig = getAlgoliaConfig(configProps.algoliaConfig, configProps.featureFlags, true // all aether pages need v2 index
  );
  const homeHandler = new HomeHandler(algoliaConfig);
  return getConfig(_objectSpread(_objectSpread({}, consoleManageAppsConfig(configProps)), {}, {
    GetMarketplaceSearch: {
      config: () => ({
        sequence: SequenceTypes.CUSTOM_ONLY,
        handler: new SearchResultHandler(algoliaConfig)
      })
    },
    GetPartnerProfile: {
      middlewaresConfig: {
        cache: {
          primary: {
            ttlMs: 60000,
            cacheKey: _ref => {
              let {
                variables
              } = _ref;
              return variables.vendorId;
            }
          }
        }
      },
      config: () => ({
        sequence: SequenceTypes.PARALLEL,
        handler: new PartnerProfileHandler(algoliaConfig)
      })
    },
    GetMarketplaceFilters: {
      config: () => ({
        sequence: SequenceTypes.CUSTOM_ONLY,
        handler: new SearchFiltersHandler(configProps.stargateBaseUrl, configProps.featureFlags, configProps.statsigClient)
      })
    },
    GetCollection: {
      middlewaresConfig: {
        cache: {
          primary: {
            ttlMs: 60000,
            cacheKey: _ref2 => {
              let {
                variables
              } = _ref2;
              return variables.slug;
            }
          }
        }
      },
      config: () => ({
        sequence: SequenceTypes.PRIMARY_FIRST,
        handler: new CollectionHandler(algoliaConfig)
      })
    },
    GetMarketplaceCategory: {
      middlewaresConfig: {
        cache: {
          primary: {
            ttlMs: 60000,
            // todo: update TTL
            cacheKey: _ref3 => {
              let {
                variables
              } = _ref3;
              return variables.slug;
            }
          }
        }
      },
      config: {
        sequence: SequenceTypes.PRIMARY_FIRST,
        handler: new CategoryHandler(algoliaConfig)
      }
    },
    GetMarketplaceAppVersionHistory: {
      config: {
        sequence: SequenceTypes.PRIMARY_ONLY,
        handler: new VersionHistoryHandler(configProps.mpacBaseUrl, configProps.macBaseUrl, configProps.wacBaseUrl)
      }
    },
    GetMarketplaceHome: {
      middlewaresConfig: {
        cache: {
          primary: {
            ttlMs: 60000,
            cacheKey: _ref4 => {
              let {
                variables
              } = _ref4;
              return variables.productId;
            }
          }
        }
      },
      config: _ref5 => {
        let {
          variables
        } = _ref5;
        return {
          sequence: variables.first === 1 ? SequenceTypes.CUSTOM_ONLY : SequenceTypes.PRIMARY_FIRST,
          handler: homeHandler
        };
      }
    }
  }));
};
export { orchestrationMpacConfig };