import { CompatibleProductId } from '@atlassian/mpac-types';
export const FILTER_KEYS = {
  PRODUCT: 'product',
  PRICE: 'price',
  PAID: 'paid',
  FREE_STARTER_TIER: 'freeStarterTier',
  HOSTING: 'hosting',
  TRUST_SIGNALS: 'trustSignals',
  CLOUD_FORTIFIED: 'cloudFortified',
  BUG_BOUNTY_PARTICIPANT: 'bugBountyParticipant',
  OTHER: 'other',
  STABLE: 'stable',
  VENDOR_SUPPORTED: 'vendorSupported',
  CATEGORIES: 'categories',
  USE_CASES: 'useCases',
  HIDDEN: 'hidden',
  CANONICAL_VERSION: 'canonicalVersion',
  DEPLOYMENTS: 'deployments',
  TOP_VENDOR: 'topVendor',
  LATEST_OF_HOSTING: 'latestOfHosting',
  IS_ATLASSIAN: 'isAtlassian',
  MARKETPLACE_TYPE: 'marketplaceType',
  MARKETING_LABELS: 'marketingLabels',
  PLUGIN_SYSTEM_VERSION: 'pluginSystemVersion'
};
export const COMPATIBLE_PRODUCT_FILTER_VALUES = {
  [CompatibleProductId.Jira]: 'jira',
  [CompatibleProductId.Confluence]: 'confluence',
  [CompatibleProductId.JiraServiceManagement]: 'jira-service-desk',
  [CompatibleProductId.Fisheye]: 'fecru',
  [CompatibleProductId.Crucible]: 'fecru',
  [CompatibleProductId.Bitbucket]: 'bitbucket',
  [CompatibleProductId.Crowd]: 'crowd',
  [CompatibleProductId.Bamboo]: 'bamboo',
  [CompatibleProductId.COMPASS]: 'compass'
};