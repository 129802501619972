export const DEFAULT_STATSIG_FEATURE_FLAGS = {
  enable_staff_pick_on_app_listing: false,
  enable_aether_experience: false,
  showAnnouncementBannerInResourcesTab: false,
  enable_aether_search_overlay: false,
  enable_aether_embedded_search_experience: false,
  show_compass_apps: false,
  show_new_jira_software_label: false,
  enable_mosaic_partner_profile_experience: false,
  enable_aether_benefits_modal: false,
  enable_aether_feedback_collector: false,
  enable_aether_applisting_page: false,
  enable_current_user_api_via_store_bff: false,
  partner_teams_tab_maker_integration: false,
  display_audit_tab_in_manage_partner_page: false,
  enable_react_ufo: false,
  enable_partner_user_api_via_console_bff_gql: false,
  show_applisting_trust_tab: false,
  enable_algolia_call_on_applising_and_dialogs: false
};
export const DEFAULT_STATSIG_EXPERIMENTS = {
  marketplace_app_install_aa_test: {
    ShowAppInstallButton: true
  },
  enable_aether_experience_experiment: {
    isExperienceEnabled: false
  }
};