import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import FeatureGates, { FeatureGateEnvironment } from '@atlaskit/feature-gate-js-client';
import { DEFAULT_STATSIG_EXPERIMENTS, DEFAULT_STATSIG_FEATURE_FLAGS } from '../statsig-context/StatsigFeatureFlags';
export { FeatureGateEnvironment as StatsigEnvironment };
export class StatsigClient {
  constructor() {
    var _this = this;
    _defineProperty(this, "isStatsigInitialized", false);
    _defineProperty(this, "evaluatedFlags", DEFAULT_STATSIG_FEATURE_FLAGS);
    _defineProperty(this, "evaluatedExperiments", DEFAULT_STATSIG_EXPERIMENTS);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _defineProperty(this, "overrides", {});
    _defineProperty(this, "getExperimentValue", function (experimentName, propertyName) {
      let defaultValue = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      if (!_this.isStatsigInitialized) {
        console.warn('Statsig client not initialized.');
        return defaultValue;
      }
      if (_this.overrides[experimentName] !== undefined && _this.overrides[experimentName][propertyName] !== undefined) {
        return _this.overrides[experimentName][propertyName];
      }
      const evaluatedValue = FeatureGates.getExperimentValue(experimentName, propertyName, defaultValue);
      _this.evaluatedExperiments = _objectSpread(_objectSpread({}, _this.evaluatedExperiments), {}, {
        [experimentName]: _objectSpread(_objectSpread({}, _this.evaluatedExperiments[experimentName]), {}, {
          [propertyName]: evaluatedValue
        })
      });
      return evaluatedValue;
    });
    _defineProperty(this, "checkGate", function (gateName) {
      let defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      if (!_this.isStatsigInitialized) {
        console.warn('Statsig client not initialized.');
        return defaultValue;
      }
      if (_this.overrides[gateName] !== undefined) {
        return Boolean(_this.overrides[gateName]);
      }
      const evaluatedValue = FeatureGates.checkGate(gateName);
      _this.evaluatedFlags[gateName] = evaluatedValue;
      return evaluatedValue;
    });
    _defineProperty(this, "initializeStatsigClientWithValues", async _ref => {
      let {
        marketplaceAnonymousId,
        environment,
        targetApp,
        clientSdkKey,
        experimentValues,
        customAttributes
      } = _ref;
      await FeatureGates.initializeFromValues({
        environment,
        targetApp,
        sdkKey: clientSdkKey
      }, {
        marketplaceAnonymousId
      }, customAttributes, experimentValues);
      this.isStatsigInitialized = true;
    });
    _defineProperty(this, "setOverride", overrideValues => {
      this.overrides = overrideValues;
    });
    _defineProperty(this, "getEvaluatedFlags", () => {
      return this.evaluatedFlags;
    });
    _defineProperty(this, "getEvaluatedExperiments", () => {
      return this.evaluatedExperiments;
    });
    // ---------------------- Experiments -------------------------
    _defineProperty(this, "marketplaceAppInstallAaTest", {
      showAppInstallButton: () => {
        return this.getExperimentValue('marketplace_app_install_aa_test', 'ShowAppInstallButton', true);
      }
    });
    _defineProperty(this, "enableAetherExperience", () => {
      return this.getExperimentValue('enable_aether_experience_experiment', 'isExperienceEnabled', false);
    });
    // ---------------------- Feature Flags ----------------------
    _defineProperty(this, "enableStaffPickOnAppListing", () => {
      return this.checkGate('enable_staff_pick_on_app_listing');
    });
    _defineProperty(this, "showAnnouncementBannerInResourcesTab", () => {
      return this.checkGate('show_announcement_banner_in_resources_tab');
    });
    _defineProperty(this, "enableAetherSearchOverlay", () => {
      return this.checkGate('enable_aether_search_overlay');
    });
    _defineProperty(this, "enableAetherSearchEmbedded", () => {
      return this.checkGate('enable_aether_embedded_search_experience');
    });
    _defineProperty(this, "showCompassApps", () => {
      return this.checkGate('show_compass_apps');
    });
    _defineProperty(this, "showNewJiraSoftwareLabel", () => {
      return this.checkGate('show_new_jira_software_label');
    });
    _defineProperty(this, "enableMosaicPartnerProfileExperience", () => {
      return this.checkGate('enable_mosaic_partner_profile_experience');
    });
    _defineProperty(this, "showChangemakersCollectionTile", () => {
      return this.checkGate('show_changemakers_collection_tile');
    });
    _defineProperty(this, "enableAetherBenefitsModal", () => {
      return this.checkGate('enable_aether_benefits_modal');
    });
    _defineProperty(this, "enableAetherFeedbackCollector", () => {
      return this.checkGate('enable_aether_feedback_collector');
    });
    _defineProperty(this, "enableAetherAppListingPage", () => {
      if (this.enableEmceeAetherExp() || this.enableAetherExperience()) {
        return this.checkGate('enable_aether_applisting_page');
      }
      return false;
    });
    _defineProperty(this, "enableCurrentUserApiViaStoreBff", () => {
      return this.checkGate('enable_current_user_api_via_store_bff');
    });
    _defineProperty(this, "enableTeamsWithMakerIntegration", () => {
      return this.checkGate('partner_teams_tab_maker_integration');
    });
    _defineProperty(this, "displayAuditTabInManagePartnerPage", () => {
      return this.checkGate('display_audit_tab_in_manage_partner_page');
    });
    _defineProperty(this, "enableReactUFO", () => {
      return this.checkGate('enable_react_ufo');
    });
    _defineProperty(this, "enableAlgoliaCallOnAppListingAndDialogs", () => {
      return this.checkGate('enable_algolia_call_on_applising_and_dialogs');
    });
    _defineProperty(this, "enablePartnerUserApiViaConsoleBffGql", () => {
      return this.checkGate('enable_partner_user_api_via_console_bff_gql');
    });
    _defineProperty(this, "enableEmceeAetherExp", () => {
      return this.checkGate('enable_emcee_aether_exp');
    });
    _defineProperty(this, "enableReactUFOEmcee", () => {
      return this.checkGate('enable_react_ufo_emcee');
    });
    _defineProperty(this, "showUpdatedPartnerTierMessage", () => this.checkGate('partner_tier_messaging_update'));
    // ---------------------- Permanent Feature Flags ----------------------
    _defineProperty(this, "showAppListingTrustTab", () => {
      // https://switcheroo.atlassian.com/ui/gates/1301e2c9-053a-4d13-9697-b06d84d481bf
      return this.checkGate('show_applisting_trust_tab');
    });
  }
  async initWithStatsigGlobalInstance() {
    // for jira and connie the statsig client is already initialized globally
    // we cannot pass the custom attributes as statsig is initialized in their backend
    this.isStatsigInitialized = true;
  }

  // IMPORTANT: Calling this method will completely re-initialize the client with a new set of flags. You will need to re-render the entire page after this completes to ensure everything picks up the new flag values. You should avoid using this frequently as it has implications on the user experience.
  async initializeStatsigClient(_ref2) {
    let {
      clientKey,
      environment,
      targetApp,
      marketplaceAnonymousId,
      tenantId,
      atlassianAccountId
    } = _ref2;
    let customAttributes = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    try {
      await FeatureGates.initialize({
        apiKey: clientKey,
        environment,
        targetApp,
        fetchTimeoutMs: 5000
      }, {
        marketplaceAnonymousId,
        tenantId,
        atlassianAccountId
      }, customAttributes);
      this.isStatsigInitialized = true;
    } catch (err) {
      console.error('Failed to initialize FeatureGates client.', err);
    }
  }
}
export default StatsigClient;