import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { COMPATIBLE_PRODUCT_FILTER_VALUES, createAlgoliaFacetFiltersFromQueryMetaData, FILTER_KEYS } from '@atlassian/mpac-utils';
import shuffle from 'lodash/shuffle';
import { AlgoliaSearchV3 } from '../../../algolia';
import { transposeAppsConnection } from '../../SearchResult/utils/AppTileTransposeUtil';
import { AttributesToRetrieve, EnterpriseAppsSectionMetaData } from './constants';
export const transformEnterpriseAppsSection = (sectionResponse, productId) => {
  var _transposeAppsConnect, _transposeAppsConnect2;
  let navigationUrl = "/search?".concat(FILTER_KEYS.TRUST_SIGNALS, "=").concat(FILTER_KEYS.CLOUD_FORTIFIED);
  if (productId) {
    navigationUrl += "&".concat(FILTER_KEYS.PRODUCT, "=").concat(COMPATIBLE_PRODUCT_FILTER_VALUES[productId]);
  }
  const enterpriseAppsSection = _objectSpread(_objectSpread({}, EnterpriseAppsSectionMetaData), {}, {
    __typename: 'MarketplaceStoreHomePageEnterpriseSection',
    id: "".concat(EnterpriseAppsSectionMetaData.id, "__").concat(productId || 'home'),
    navigationUrl,
    apps: ((_transposeAppsConnect = transposeAppsConnection({
      customResponse: sectionResponse
    })) === null || _transposeAppsConnect === void 0 ? void 0 : (_transposeAppsConnect2 = _transposeAppsConnect.edges) === null || _transposeAppsConnect2 === void 0 ? void 0 : _transposeAppsConnect2.map(edge => edge.node)) || []
  });
  return enterpriseAppsSection;
};
export const transformSectionsApps = _ref => {
  let {
    sectionsMetaInfo,
    sectionsAppsData,
    productId
  } = _ref;
  const sectionsApps = [];
  sectionsMetaInfo.forEach((section, index) => {
    const sectionResponse = sectionsAppsData[index];
    const {
      hits
    } = sectionResponse.results[0];
    if (hits && hits.length > 0) {
      var _transposeAppsConnect3, _transposeAppsConnect4;
      sectionsApps.push(_objectSpread(_objectSpread({}, section), {}, {
        id: "".concat(section.id, "__").concat(productId || 'home'),
        apps: shuffle((_transposeAppsConnect3 = transposeAppsConnection({
          customResponse: sectionResponse
        })) === null || _transposeAppsConnect3 === void 0 ? void 0 : (_transposeAppsConnect4 = _transposeAppsConnect3.edges) === null || _transposeAppsConnect4 === void 0 ? void 0 : _transposeAppsConnect4.map(edge => edge.node)) || []
      }));
    }
  });
  return sectionsApps;
};
export const getTransformSectionsApps = async _ref2 => {
  var _primaryResponse$mark, _primaryResponse$mark2;
  let {
    primaryResponse,
    additionalFilters,
    algoliaConfig,
    productId
  } = _ref2;
  const sectionsMetaInfo = primaryResponse === null || primaryResponse === void 0 ? void 0 : (_primaryResponse$mark = primaryResponse.marketplaceStore) === null || _primaryResponse$mark === void 0 ? void 0 : (_primaryResponse$mark2 = _primaryResponse$mark.homePage) === null || _primaryResponse$mark2 === void 0 ? void 0 : _primaryResponse$mark2.sections.filter(section => section.__typename !== 'MarketplaceStoreHomePageFeaturedSection');
  let sectionsApps = [];
  if (sectionsMetaInfo && sectionsMetaInfo.length > 0) {
    const sectionsAppsData = await Promise.all(sectionsMetaInfo.map(section => {
      let facetFilters = [];
      facetFilters = createAlgoliaFacetFiltersFromQueryMetaData(section.algoliaQueryMetadata);
      if (additionalFilters && additionalFilters.length > 0) {
        facetFilters.push(...additionalFilters);
      }
      return AlgoliaSearchV3({
        applicationId: algoliaConfig.algoliaAppId,
        apiKey: algoliaConfig.algoliaApiKey,
        indexName: section.algoliaQueryMetadata.searchIndex,
        attributesToHighlight: [],
        attributesToRetrieve: AttributesToRetrieve,
        facetFilters,
        hitsPerPage: section.appsFetchCount
      });
    }));
    sectionsApps = transformSectionsApps({
      sectionsMetaInfo,
      sectionsAppsData,
      productId
    });
  }
  return sectionsApps;
};