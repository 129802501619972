import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import {
  breakpointValues,
  ChevronDownIcon,
  ChevronUpIcon,
  useResponsiveBreakpoint,
} from '@atlassian/mpac-primitives';
import React, { useState } from 'react';

import BootstrapConfig from '../../../../bootstrapConfig';
import { usePartnerPermissions } from '../../../../hooks/usePartnerPermissions';
import { useGlobalNavAnalytics } from '../Analytics/useGlobalNavAnalytics';
import { dataTestIds } from '../constants';
import { getQuickLinkItems } from './getQuickLinkItems';
import {
  Background,
  Container,
  DesktopLink,
  DropdownTriggerIcon,
  DropdownTriggerLink,
  Label,
  LeftContent,
  PartnerName,
} from './PartnerQuicklinks.styles';

import type { QuickLinkItems } from './getQuickLinkItems';
import type { Ref } from 'react';

export const MobileLinks = ({
  quickLinkItems,
  onLinkClick,
}: {
  quickLinkItems: QuickLinkItems;
  onLinkClick: (string) => void;
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === 'Enter') {
      setIsDropdownOpen(true);
    }
  };

  const dropdownTrigger = ({ triggerRef, ...props }) => (
    <DropdownTriggerLink
      isOpen={isDropdownOpen}
      tabIndex={0}
      onKeyDown={handleKeyDown}
      ref={triggerRef as Ref<HTMLButtonElement>}
      {...props}
    >
      Explore partner links
      <DropdownTriggerIcon>
        {isDropdownOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </DropdownTriggerIcon>
    </DropdownTriggerLink>
  );

  const dropdownItems = () =>
    quickLinkItems.map((item, index) => (
      <DropdownItem
        key={index}
        href={item.path}
        onClick={() => onLinkClick(item.linkActionSubjectId)}
      >
        {item.name}
      </DropdownItem>
    ));

  return (
    <DropdownMenu
      appearance="tall"
      placement="bottom-end"
      isOpen={isDropdownOpen}
      onOpenChange={({ isOpen }) => setIsDropdownOpen(isOpen)}
      trigger={dropdownTrigger}
    >
      <DropdownItemGroup>{dropdownItems()}</DropdownItemGroup>
    </DropdownMenu>
  );
};

export const DesktopLinks = ({
  quickLinkItems,
  onLinkClick,
}: {
  quickLinkItems: QuickLinkItems;
  onLinkClick: (string) => void;
}) => {
  return (
    <>
      <Label>Partner Links:</Label>
      {quickLinkItems.map((item, index) => (
        <DesktopLink
          key={index}
          href={item.path}
          size="medium"
          onClick={() => onLinkClick(item.linkActionSubjectId)}
        >
          {item.name}
        </DesktopLink>
      ))}
    </>
  );
};

export type PartnerQuicklinksProps = {
  lastVisitedPartnerName: string;
  lastVisitedPartnerId: number;
};

export const PartnerQuicklinks = ({
  lastVisitedPartnerId,
  lastVisitedPartnerName,
}: PartnerQuicklinksProps) => {
  const { mpacBaseUrl } = BootstrapConfig.get();
  const { value: breakpoint } = useResponsiveBreakpoint();
  const isMobile = breakpoint < breakpointValues.sm;

  const { permissions } = usePartnerPermissions(mpacBaseUrl, lastVisitedPartnerId);

  const { firePartnerQuickLinksClickEvent } = useGlobalNavAnalytics();

  if (!permissions) {
    return null;
  }

  const quickLinkItems = getQuickLinkItems(permissions, lastVisitedPartnerId);

  const onLinkClick = (linkActionSubjectId) =>
    firePartnerQuickLinksClickEvent(
      lastVisitedPartnerId,
      lastVisitedPartnerName,
      linkActionSubjectId
    );

  return (
    <Background data-testId={dataTestIds.PARTNER_QUICK_LINKS}>
      <Container>
        <LeftContent role="navigation" aria-label="Quick links">
          {isMobile ? (
            <MobileLinks quickLinkItems={quickLinkItems} onLinkClick={onLinkClick} />
          ) : (
            <DesktopLinks quickLinkItems={quickLinkItems} onLinkClick={onLinkClick} />
          )}
        </LeftContent>
        <PartnerName>{lastVisitedPartnerName}</PartnerName>
      </Container>
    </Background>
  );
};
