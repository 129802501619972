import { fetchOk } from '@atlassian/mpac-fetch-wrapper';
export const updatePartnerDetails = (mpacBaseUrl, partnerId, patches) => fetchOk("".concat(mpacBaseUrl, "/rest/2/vendors/").concat(partnerId), {
  method: 'PATCH',
  body: JSON.stringify(patches),
  headers: {
    'Content-Type': 'application/json-patch+json;charset=UTF-8'
  }
});

/* List of Apps published by a Partner */
export const getPartnerApps = (mpacBaseUrl, partnerId) => fetchOk("".concat(mpacBaseUrl, "/gateway/api/marketplace/catalog/partners/").concat(partnerId, "/promotion-eligible-apps")).then(response => response.json());
export const getPartnersBySearchQuery = (mpacBaseUrl, query) => fetchOk("".concat(mpacBaseUrl, "/rest/1.0/vendors/search/admin?q=").concat(query)).then(response => response.json()).then(response => response.vendors);
export const archivePartner = (mpacBaseUrl, partnerId) => fetchOk("".concat(mpacBaseUrl, "/rest/1.0/vendors/").concat(partnerId, "/archive"), {
  method: 'POST'
}).then(response => response.json());
export const fetchPartnersForUser = mpacBaseUrl => fetchOk("".concat(mpacBaseUrl, "/gateway/api/marketplace/catalog/user-partners")).then(response => response.json());
export const getPartnerPermissions = (mpacBaseUrl, partnerId) => fetchOk("".concat(mpacBaseUrl, "/gateway/api/marketplace/catalog/partner-permissions/").concat(partnerId)).then(response => response.json());
export const uploadPartnerLogo = (mpacBaseUrl, file) => fetchOk("".concat(mpacBaseUrl, "/rest/2/assets/image/logo"), {
  method: 'POST',
  body: file
}).then(response => response.json());