import { useFeatureFlags } from '@atlassian/mpac-feature-flags';
import { useLocation } from '@atlassian/mpac-ui';
import React, { lazy, Suspense } from 'react';

import HelpDropdown from './HelpDropdown';

type ShowHelpProps = {
  userLoggedIn: boolean;
};
const InProductHelpCodeSplit = lazy(
  () => import(/* webpackChunkName: "inProductHelp" */ './InProductHelp/InProductHelp')
);

const ShowHelp = ({ userLoggedIn }: ShowHelpProps) => {
  const { pathname } = useLocation();
  const { enableInProductHelpPartnerReporting } = useFeatureFlags();
  return userLoggedIn &&
    enableInProductHelpPartnerReporting &&
    // @ts-expect-error [MC-2850] - TS2532 - Object is possibly 'undefined'.
    pathname.includes('manage/vendors') ? (
    <Suspense fallback={<div />}>
      <InProductHelpCodeSplit />
    </Suspense>
  ) : (
    <HelpDropdown />
  );
};

export default ShowHelp;
