import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { fetchOk } from '@atlassian/mpac-fetch-wrapper';
import { MarketplaceConsoleAppDistributionsGroupByKey } from '@atlassian/mpac-types/client-graphql-schema-types';
import { getPartnerApisHeaders } from './partnerApisHeaders';
const getAttributes = groupBy => {
  const attributes = [{
    name: 'APP_KEY'
  }];
  switch (groupBy) {
    case MarketplaceConsoleAppDistributionsGroupByKey.AppVersion:
      attributes.push({
        name: 'APP_VERSION'
      });
      break;
    case MarketplaceConsoleAppDistributionsGroupByKey.ParentMajorVersion:
      attributes.push(...[{
        name: 'PARENT_SOFTWARE'
      }, {
        name: 'PARENT_SOFTWARE_MAJOR_VERSION'
      }]);
      break;
    case MarketplaceConsoleAppDistributionsGroupByKey.ParentMinorVersion:
      attributes.push(...[{
        name: 'PARENT_SOFTWARE'
      }, {
        name: 'PARENT_SOFTWARE_MINOR_VERSION'
      }]);
      break;
    case MarketplaceConsoleAppDistributionsGroupByKey.ParentAllVersions:
      attributes.push(...[{
        name: 'PARENT_SOFTWARE'
      }, {
        name: 'PARENT_SOFTWARE_VERSION'
      }]);
      break;
  }
  return attributes;
};
const getRequestBody = options => {
  return {
    dateRange: {
      startDate: options.startDate,
      endDate: options.endDate
    },
    granularity: options.granularity,
    attributes: getAttributes(options.groupBy),
    metrics: {
      metricFields: [{
        name: 'INSTALL_COUNT'
      }]
    },
    attributesFilter: {
      expr: {
        andGroup: [{
          filter: {
            fieldName: 'APP_KEY',
            oneFilter: {
              stringFilter: {
                value: options.appKey
              }
            }
          }
        }]
      }
    }
  };
};
export const getActiveInstallsData = (mpacBaseUrl, options) => {
  return fetchOk("".concat(mpacBaseUrl, "/rest/2/vendors/").concat(options.partnerId, "/partner-metrics?limit=180"), {
    method: 'POST',
    body: JSON.stringify(getRequestBody(options)),
    headers: _objectSpread({
      'Content-Type': 'application/json;charset=UTF-8',
      credentials: 'same-origin'
    }, getPartnerApisHeaders())
  }).then(response => response.json());
};