import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { AlgoliaSearch } from '../../algolia';
import { BaseHandler } from '../../graphql-orchestrator-link';
import { transformFilters } from './utils/AlgoliaFiltersTransformUtil';
import { resolveAlgoliaSortIndexAndFilters } from './utils/AlgoliaSortUtil';
import { transposeAppsConnection } from './utils/AppTileTransposeUtil';
export class SearchResultHandler extends BaseHandler {
  constructor(algoliaConfig) {
    super();
    _defineProperty(this, "algoliaConfig", void 0);
    this.algoliaConfig = algoliaConfig;
  }
  async fetchCustom(operation, _transformedOperation, _sequenceConfigValue, _primaryResponse) {
    const {
      query,
      filters,
      sort,
      after,
      first,
      additionalFilters
    } = operation.variables;
    const {
      transformedAlgoliaIndex,
      sortFilters
    } = resolveAlgoliaSortIndexAndFilters(this.algoliaConfig.algoliaSearchIndex, sort, filters);
    const facetFilters = transformFilters(filters, sortFilters, additionalFilters);
    const response = await AlgoliaSearch({
      applicationId: this.algoliaConfig.algoliaAppId,
      apiKey: this.algoliaConfig.algoliaApiKey,
      indexName: transformedAlgoliaIndex,
      query,
      facetFilters,
      after,
      first
    });
    return response;
  }
  responseTransformer(operation, _transformedOperation, _sequenceConfigValue, _primaryResponse, customResponse) {
    const newResponse = {
      marketplaceSearch: customResponse ? transposeAppsConnection({
        customResponse,
        query: operation.variables.query
      }) : null
    };
    return newResponse;
  }
}