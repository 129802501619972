/*
 * This is the browser entry point.
 * THIS FILE IS MEANT TO RUN ONLY ON THE BROWSER.
 */
import { setGlobalTheme } from '@atlaskit/tokens';
import { StatsigClient, StatsigContext } from '@atlassian/mpac-feature-flags';
import { ErrorBoundary, Router } from '@atlassian/mpac-ui';
import { initSentry, setSentryUserId } from '@atlassian/mpac-utils';
import UFOSegment from '@atlassian/ufo-segment';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import React from 'react';
import { hydrate } from 'react-dom';
import { createRoot } from 'react-dom/client';
import { setConfiguration as setReactGridSystemConfig } from 'react-grid-system';
import { HelmetProvider } from 'react-helmet-async';
import LooselyLazy, { MODE } from 'react-loosely-lazy';

import { getCurrentDeployedBranch, isBranchDeployment } from 'common/utils/branchDeploy';
import { IsomorphicApp } from './app';
import BootstrapConfigSingleton from './app/bootstrapConfig';
import AnalyticsListener from './app/components/common/AnalyticsListener';
import { AMKT_FRONTEND_RELEASE_VERSION } from './app/constants';
import startAMKTTelemetry from './app/telemetry/start-telemetry';
import { createBrowserRenderContext, initAceAnalytics } from './app/utils/entry-browser.utils';
import { initMetalMonitoring } from './app/utils/metalMonitoring';
import { getBootstrapConfig, getInitialConfig } from './bootstrap';
import AppErrorFallback from './common/components/AppErrorFallback';
import renderBrowserSupportBanner from './common/components/BrowserSupportBanner';
import { showIEDeprecationFlagIfNeeded } from './common/components/IEDeprecationFlag';
import registerClicktaleEvents from './common/utils/analytics/clicktale';
import { EMOTION_CACHE_KEY } from './ssr/constants';

import type {
  ApplicationConfig,
  BootstrapConfig,
} from './application-config/types/BootstrapConfig';
import type { StatsigClientType } from '@atlassian/mpac-feature-flags';

export const getBrowserApp = (
  bootstrapConfig: BootstrapConfig,
  statsigClient: StatsigClientType
) => {
  const { apolloClient, reduxStore, browserHistory } = createBrowserRenderContext(
    bootstrapConfig,
    statsigClient
  );

  return (
    <UFOSegment name="amkt-root">
      <ErrorBoundary boundaryName="BrowserApp" fallback={<AppErrorFallback />}>
        <StatsigContext.Provider value={{ statsigClient }}>
          <AnalyticsListener>
            <Router history={browserHistory}>
              <HelmetProvider>
                <IsomorphicApp
                  bootstrapConfig={bootstrapConfig}
                  apolloClient={apolloClient}
                  reduxStore={reduxStore}
                />
              </HelmetProvider>
            </Router>
          </AnalyticsListener>
        </StatsigContext.Provider>
      </ErrorBoundary>
    </UFOSegment>
  );
};

const mountReactApp = ({
  container,
  bootstrapConfig,
  shouldHydrate,
  statsigClient,
}: {
  container: HTMLElement | null | undefined;
  bootstrapConfig: BootstrapConfig;
  shouldHydrate: boolean;
  statsigClient: StatsigClientType;
}) => {
  const app = getBrowserApp(bootstrapConfig, statsigClient);

  if (!container) {
    console.warn(`Failed to mount app, could not find root container`);
    return;
  }

  if (shouldHydrate) {
    // Hydration of the ids in `data-emotion-css` will automatically occur when the cache is created
    const cache = createCache({ key: EMOTION_CACHE_KEY });
    // hydrate is deprecated in React 18 and is replaced by hydrateRoot. This works like React 17 hydrate.
    // We need to debug hydration mismatch which is reported only with React 18 before moving to hydrateRoot
    hydrate(<CacheProvider value={cache}>{app}</CacheProvider>, container);
  } else {
    const root = createRoot(container);
    root.render(app);
  }
};

/**
 * Things that can be initialized before we mount the react app using
 * only the static application config.
 */
const beforeStart = (applicationConfig: ApplicationConfig) => {
  // Initialize error monitoring using Sentry
  initSentry({
    dsn: applicationConfig.sentry.dsn,
    environment: applicationConfig.sentry.environment,
    releaseVersion: AMKT_FRONTEND_RELEASE_VERSION,
    setupGlobalListeners: true,
  });

  setReactGridSystemConfig({ maxScreenClass: 'xl' });
  showIEDeprecationFlagIfNeeded();
  renderBrowserSupportBanner('browser-support-banner');

  // initialize lazy loading
  LooselyLazy.init({
    crossOrigin: 'anonymous',
    mode: MODE.RENDER,
  });

  // Initialize performance monitoring using Metal
  initMetalMonitoring({
    id: applicationConfig.metal.id,
    env: applicationConfig.metal.env,
    version: 'AMKT_FRONTEND_STATIC',
  });
};

/**
 * Starts the app by fetching the bootstrap data, initializing global listeners with user context
 * and mounting the react application.
 */
const startApp = async (applicationConfig: ApplicationConfig) => {
  try {
    const container = document.getElementById('amkt-frontend-content');

    const statsigClient = new StatsigClient();

    // get/build all configs
    const urlSearch = window.location.search;
    const initialConfig = await getInitialConfig({
      applicationConfig,
      urlSearch,
      ...(isBranchDeployment() && {
        useFrontendBranch: getCurrentDeployedBranch(),
      }),
      statsigClient,
    });
    const bootstrapConfig = getBootstrapConfig(applicationConfig, initialConfig);
    BootstrapConfigSingleton.set(bootstrapConfig);

    // before mount -- setup global listeners / handlers
    setSentryUserId(initialConfig.frontendBootstrap.launchDarklyUserKey);
    startAMKTTelemetry(container, bootstrapConfig, AMKT_FRONTEND_RELEASE_VERSION, statsigClient);
    initAceAnalytics(bootstrapConfig);

    // mount react app
    mountReactApp({
      container,
      bootstrapConfig,
      shouldHydrate: initialConfig.shouldHydrate,
      statsigClient,
    });

    // after mount
    if (applicationConfig.clicktaleId) {
      // TODO: Is this really being set? Since bootstrap did not include it previously
      registerClicktaleEvents(applicationConfig.clicktaleId);
    }
  } catch (error) {
    console.error('Failed to bootstrap the application', error);
  }
};

/**
 * Runs beforeStart() & startApp() in order
 */
const run = () => {
  // @ts-expect-error [MC-2850] - TS2339 - Property 'applicationConfig' does not exist on type 'Window & typeof globalThis'.
  const config: ApplicationConfig = window.applicationConfig;

  setGlobalTheme({ colorMode: 'light', spacing: 'spacing', typography: 'typography-adg3' });
  beforeStart(config);
  startApp(config);
};

run();
