import AnalyticsWebClient, { originType } from '@atlassiansox/analytics-web-client';

import type { ApplicationConfig } from '../application-config/types/BootstrapConfig';

type GetAnalyticsClientArgs = {
  config: ApplicationConfig;
};

export const getMockAnalyticsClient = (): AnalyticsWebClient => {
  const noop = () => {
    /* do nothing since it's a mock */
  };
  const noopPromise = () => Promise.resolve();

  // @ts-expect-error [MC-2850] - TS2740 - Type '{ setEmbeddedProduct: () => void; clearEmbeddedProduct: () => void; setSubproduct: () => void; setOriginTracingHandlers: () => Promise<void>; setTenantInfo: () => void; clearTenantInfo: () => void; ... 19 more ...; onEvent: () => Promise<...>; }' is missing the following properties from type 'AnalyticsWebClient': _apdexEvent, _context, eventDelayQueue, _historyReplaceFn, and 31 more.
  return {
    setEmbeddedProduct: noop,
    clearEmbeddedProduct: noop,
    setSubproduct: noop,
    setOriginTracingHandlers: noopPromise,
    setTenantInfo: noop,
    clearTenantInfo: noop,
    setOrgInfo: noop,
    setWorkspaceInfo: noop,
    clearOrgInfo: noop,
    setUserInfo: noop,
    clearUserInfo: noop,
    setUIViewedAttributes: noop,
    clearUIViewedAttributes: noop,
    sendIdentifyEvent: noopPromise,
    sendScreenEvent: noopPromise,
    sendTrackEvent: noopPromise,
    sendUIEvent: noopPromise,
    sendOperationalEvent: noopPromise,
    startUIViewedEvent: noop,
    stopUIViewedEvent: noop,
    resetUIViewedTimers: noop,
    startApdexEvent: noop,
    getApdexStart: noop,
    stopApdexEvent: noop,
    startLowPriorityEventDelay: noop,
    stopLowPriorityEventDelay: noop,
    onEvent: noopPromise,
  };
};

export const getAnalyticsClient = ({ config }: GetAnalyticsClientArgs): AnalyticsWebClient => {
  const origin = navigator.userAgent.includes('Electron/') ? originType.DESKTOP : originType.WEB;
  return new AnalyticsWebClient(
    {
      env: config.analytics.productAnalytics.env,
      product: 'marketplace',
      origin,
    },
    {
      useLegacyUrl:
        config.analytics.productAnalytics.env === 'local' ||
        config.analytics.productAnalytics.env === 'dev',
    }
  );
};
